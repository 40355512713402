import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isShowLoading: false, // 全局 loading
        // 判断当前用户消息是否已经全部读了
        isRead: 0,
        // 左侧菜单栏数据
        menuItems: [{
            name: 'home', // 要跳转的路由名称 不是路径
            size: 18, // icon大小
            type: 'el-icon-home', // icon类型
            text: '首页', // 文本内容
        },
        {
            text: '收益管理',
            type: 'el-icon-earnings',
            children: [
                {
                    type: 'el-icon-earnings',
                    name: 'earnings',
                    text: '收益明细',
                },
                {
                    type: 'el-icon-earnings',
                    name: 'withdraw',
                    text: '提现明细',
                },
            ],
        },
        {
            text: '订单管理',
            type: 'el-icon-order',
            children: [
                {
                    type: 'el-icon-order',
                    name: 'orderview',
                    text: '订单查看',
                },
            ],
        },
        {
            text: '团队管理',
            type: 'el-icon-team',
            children: [
                {
                    type: 'el-icon-team',
                    name: 'teamsize',
                    text: '团队人数',
                },
                {
                    type: 'el-icon-administration',
                    name: 'usercontrol',
                    text: '团队列表',
                },
            ],
        },
        {
            text: '用户管理',
            type: 'el-icon-administration',
            children: [
                {
                    type: 'el-icon-icon-lingdang',
                    name: 'information',
                    text: '消息通知',
                }
            ],
        }],
        // 不是合伙人
        menuItemss: [{
            name: 'home', // 要跳转的路由名称 不是路径
            size: 18, // icon大小
            type: 'el-icon-home', // icon类型
            text: '首页', // 文本内容
        },
        {
            text: '团队管理',
            type: 'el-icon-team',
            children: [
                {
                    type: 'el-icon-team',
                    name: 'teamsize',
                    text: '团队人数',
                },
                {
                    type: 'el-icon-administration',
                    name: 'usercontrol',
                    text: '团队列表',
                },
            ],
        },
        {
            text: '用户管理',
            type: 'el-icon-administration',
            children: [
                {
                    type: 'el-icon-icon-lingdang',
                    name: 'information',
                    text: '消息通知',
                }
            ],
        }]
    },
    mutations: {
        setMenus(state, items) {
            state.menuItems.push(items)
        },
        setLoading(state, isShowLoading) {
            state.isShowLoading = isShowLoading
        },
        // 获取用户消息全部是否已读
        isReadBtn(state, id) {
            // localStorage.setItem('isReadIndex', res.data.content.isRead)
            state.isRead = id
        },
    },
})

export default store
