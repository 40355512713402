<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      keepAliveData: ['manage'],
    }
  },
  created() {
    // this.createdfirstStop()

    //平台、设备和操作系统
    var system = {
      win: false,
      mac: false,
      xll: false,
    }
    //检测平台
    var p = navigator.platform
    system.win = p.indexOf('Win') == 0
    system.mac = p.indexOf('Mac') == 0
    system.x11 = p == 'X11' || p.indexOf('Linux') == 0

    if (system.win || system.mac || system.xll) {
      //PC端
    } else {
      //移动端跳转的链接
      window.location.href = 'https://h5partner.xmw1688.cn/'
    }
  },
  computed: {
    ...mapState(['isShowLoading']),
  },
  methods: {
    //-----------------------
    createdfirstStop() {
      this.$axios.get('https://baseurl.xmw1688.cn/').then(
        (res) => {
          let str = res.data.baseurl
          localStorage.setItem('connector', str)
        },
        (err) => {
          this.createdSecondStop()
        }
      )
    },

    // 第二
    createdSecondStop() {
      this.$axios.get('https://baseurl.dwhk918.cn/').then(
        (res) => {
          let str = res.data.baseurl
          localStorage.setItem('connector', str)
        },
        (err) => {
          this.createdThreeStop()
        }
      )
    },
    // 第三
    createdThreeStop() {
      this.$axios.get('https://baseurl.yuncangxiaodian.com/').then(
        (res) => {
          let str = res.data.baseurl
          localStorage.setItem('connector', str)
        },
        (err) => {
          let str = 'https://api.xmw1688.cn/api'
          localStorage.setItem('connector', str)
        }
      )
    },
  },
}
</script>

<style lang="less">
body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .el-month-table td.today .cell {
    color: #606266;
    font-weight: 400;
  }

  .el-date-table td.today span {
    color: #606266;
    font-weight: 400;
  }

  .el-table tr {
    height: 70px;
  }
}
li,
ul,
p,
div,
body,
html,
table {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input {
  cursor: pointer;
}
html,
body {
  height: 100%;
  overflow: hidden;
}
li {
  list-style: none;
}
#app {
  height: 100%;
}
/* loading */
.global-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.van-toast--default {
  text-align: center;
  z-index: 111111 !important;
}

.van-toast--default .van-loading {
  text-align: center;
  margin: 10px auto;
}

.van-overlay {
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
