import Vue from 'vue'
import axios from 'axios'
import ViewUI from 'view-design'
import App from './App'
import store from './store'
import router from './router'
import 'view-design/dist/styles/iview.css'
import './permission'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import  './utils/rem'

Vue.config.productionTip = false
Vue.use(ViewUI)

import './common/font/iconfont.css'
import './common/fontIcon/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'
import element from './plugins/element'
Vue.use(element)

import * as echarts from 'echarts' //引入echarts
Vue.prototype.$echarts = echarts //引入组件

Vue.prototype.$axios = axios

import qs from 'qs';
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
