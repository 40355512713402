import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('../components/Login.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '404' },
        component: () => import('../components/404.vue'),
    },
    { path: '/', redirect: '/home' },

]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '首页' },
        component: () => import('../views/home.vue'),
    },
    earnings: {
        path: 'earnings',
        name: 'earnings',
        meta: { title: '收益明细' },
        component: () => import('../views/earnings.vue'),
    },
    withdraw: {
        path: 'withdraw',
        name: 'withdraw',
        meta: { title: '提现明细' },
        component: () => import('../views/withdraw.vue'),
    },
    orderview: {
        path: 'orderview',
        name: 'orderview',
        meta: { title: '订单查看' },
        component: () => import('../views/orderview.vue'),
    },
    teamsize: {
        path: 'teamsize',
        name: 'teamsize',
        meta: { title: '团队人数' },
        component: () => import('../views/teamsize.vue'),
    },
    usercontrol: {
        path: 'usercontrol',
        name: 'usercontrol',
        meta: { title: '用户管理' },
        component: () => import('../views/usercontrol.vue'),
    },
    information: {
        path: 'information',
        name: 'information',
        meta: { title: '消息通知' },
        component: () => import('../views/information.vue'),
    },
    //----------新增模块
    autonym: {
        path: 'autonym',
        name: 'autonym',
        meta: { title: '实名认证' },
        component: () => import('../views/partner-Application/autonym.vue'),
    },
    bankcard: {
        path: 'bankcard',
        name: 'bankcard',
        meta: { title: '新增银行卡' },
        component: () => import('../views/partner-Application/bankcard.vue'),
    },
    alipay: {
        path: 'alipay',
        name: 'alipay',
        meta: { title: '新增支付宝' },
        component: () => import('../views/partner-Application/alipay.vue'),
    },
    cashdeposit: {
        path: 'cashdeposit',
        name: 'cashdeposit',
        meta: { title: '保证金' },
        component: () => import('../views/partner-Application/cashdeposit.vue'),
    }
}

const createRouter = () => new Router({
    routes: commonRoutes,
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
